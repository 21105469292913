export default class SlickVehicleGallery {
    public static init() {
		const autoPlay = ($('.phyron-thumbnail').length > 0 ? false: true);
		if ($('.slick--vehicle-gallery-main').length > 0) {
			$('.slick--vehicle-gallery-main').slick({
				slidesToShow: 1,
				lazyLoad: "ondemand",
				slidesToScroll: 1,
				arrows: false,
				prevArrow: "<span class='slick-prev main'>&lt;</span>",
				nextArrow: "<span class='slick-next main'>&gt;</span>",
				asNavFor: '.slick--vehicle-gallery-thumbs',
				fade: true,
				autoplay: autoPlay,
				autoplaySpeed: 3000
			});
			$('.slick--vehicle-gallery-thumbs').slick({
				slidesToShow: 5,
				lazyLoad: "ondemand",
				slidesToScroll: 1,
				responsive: [
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1
						}
					}
				],
				arrows: true,
				prevArrow: "<span class='slick-prev thumbs'>&lt;</span>",
				nextArrow: "<span class='slick-next thumbs'>&gt;</span>",
				asNavFor: '.slick--vehicle-gallery-main',
				dots: false,
				centerMode: false,
				focusOnSelect: true,
				autoplay: autoPlay,
				autoplaySpeed: 3000
			});
			$('.slick--vehicle-gallery-main').each(function () {
				(<any>$(this)).magnificPopup({
					delegate: 'a',
					type: 'image',
					gallery: {
						enabled: true
					}
				});
			});
		}
	}
}